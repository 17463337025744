import { Col, Row } from "antd"
import back from "./back"
import { useEffect } from "react"
import { useState } from "react"
import { StepBackwardOutlined } from "@ant-design/icons"
export default function KvozaAttachments(props){
    const [messages, setMessages] = useState([])
    const users = props.users
    const [photo, setPhoto] = useState(undefined)
    useState(()=>{
        fetch(back + "/mediamessages")
        .then(response => response.json())
        .then((data) => {
            data.reverse()
            setMessages(data)
        })
    },[])

    return (
        <div>
            {
                photo === undefined ?
                <Row style={{
                    width: "100%",
                }}>
                    {messages.map((message) => {
                        if(message.media_id !== null && message.type === "photo"){
                            return (
                                <Col 
                                onClick={() => {
                                    setPhoto(message)
                                }}
                                span={8} key={message.id}>
                                    <div style={{
                                        paddingLeft: 5,
                                        paddingRight: 5,
                                        marginBottom: 10,
                                        backgroundImage: `url(https://stasik.duckdns.org/media/${message.media_id})`,
                                        width: "calc(100%-10px)",
                                        height: "100px",
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                    }}/>
                                </Col>
                            )
                        }
                    })}
                </Row>
                :
                <>
                    <div className="imagePreview">
                        <StepBackwardOutlined style={{fontSize: 30, cursor: "pointer"}} onClick={() => setPhoto(undefined)}/>
                        <div className="imageContainer" style={{backgroundImage: `url(${back}/media/${photo.media_id})`}}></div>
                        <br/>
                        <div className="messageInfo">
                            <div className="useravatar" style={{backgroundImage: `url(${back}/media/${users[photo.user_id].picture})`}}></div>
                            <div>
                                <p
                                    style={{
                                        fontSize: 16,
                                    }}
                                >{users[photo.user_id].username}</p>
                                <p
                                    style={{
                                        fontSize: 12,
                                        color: "gray"
                                    }}
                                >{photo.text}</p>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}