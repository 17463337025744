import back from "./back"

export default function Users(props) {
    const users = props.users
    return (
        <div className="users">
            {Object.keys(users).map((key) => (
                <div className="user" key={key}>
                    <div className="useravatar" 
                    onClick={
                        () => {
                            window.open("https://t.me/"+users[key].username, "_blank")
                        }
                    }
                    style={
                            users[key].picture !== "" ? {
                                backgroundImage: `url(${back}/media/${users[key].picture})`
                            } : {
                                backgroundColor: "lightgray"
                            }
                        }></div>
                    <div className="username"
                        onClick={
                            () => {
                                window.open("https://t.me/"+users[key].username, "_blank")
                            }
                        
                        }
                    >{users[key].username}</div>
                </div>
            ))}
        </div>
    )
}