import {Modal, Tabs} from "antd"
import { useState } from "react"
import Users from "./Users"
import KvozaAttachments from "./KvozaAttachments"

export default function KvozaHeader(props){
    const [open, setModalOpen] = useState(false)
    const users = props.users
    const items = [
        {
            label: "Users",
            key: '1',
            children: <Users users={users}/>
        },
        {
            label: "Attachments",
            key: '2',
            children: <KvozaAttachments users={users}/>
        }
    ]
    return (
        <>
            <div className="header" onClick={() => setModalOpen(true)}>
                <div className="avatar"></div>
                <span className="title">Pervaki</span>
                <br/>
                <span className="subtitle">{users && <>{Object.keys(users).length} active users</>}</span>
                
            </div>
            <Modal open={open} onCancel={() => setModalOpen(false)} footer={null}>
                <Tabs defaultActiveKey="1" items={items}/>
            </Modal>
        </>
    )
}